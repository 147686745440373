import { render, staticRenderFns } from "./Tasks.vue?vue&type=template&id=148bda12&scoped=true&"
import script from "./Tasks.vue?vue&type=script&lang=js&"
export * from "./Tasks.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/apps/scrumboard.css?vue&type=style&index=0&id=148bda12&scoped=true&lang=css&"
import style1 from "@/assets/css/forms/theme-checkbox-radio.css?vue&type=style&index=1&id=148bda12&scoped=true&lang=css&"
import style2 from "@/assets/css/components/tabs-accordian/custom-accordions.css?vue&type=style&index=2&id=148bda12&scoped=true&lang=css&"
import style3 from "@/assets/css/tables/table-basic.css?vue&type=style&index=3&id=148bda12&scoped=true&lang=css&"
import style4 from "./Tasks.vue?vue&type=style&index=4&id=148bda12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148bda12",
  null
  
)

export default component.exports