<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="action-btn layout-top-spacing mb-5 d-flex">
        <h3>
          <span class="badge badge-success"
            >{{ search.month }}, {{ search.year }}</span
          >
        </h3>

        <div class="d-flex ml-auto">
          <button
            data-toggle="modal"
            data-target="#searchModal"
            class="btn btn-primary"
          >
            <i class="fa fa-search"></i>
          </button>

          <button
            data-toggle="modal"
            data-target="#scoreChartModal"
            class="btn btn-dark mx-1"
          >
            <i class="fa fa-chart-bar"></i>
          </button>

          <button
            data-toggle="modal"
            data-target="#flowChartModal"
            class="btn btn-warning mx-1"
          >
            <i class="fa fa-question"></i>
          </button>
        </div>
      </div>

      <div class="row scrumboard" id="cancel-row">
        <div class="col-lg-12 layout-spacing">
          <div class="task-list-section" id="taskScroll">
            <div
              class="task-list-container"
              v-for="(week, index) in weeks"
              :key="index"
            >
              <div class="connect-sorting">
                <div class="task-container-header">
                  <h6 class="s-heading">Week {{ index + 1 }}</h6>
                </div>
                <small class="text-muted text-center"
                  >{{ formatDate(week[0]) }} - {{ formatDate(week[1]) }}
                </small>
                <hr />

                <div
                  class="connect-sorting-content ui-sortable"
                  data-sortable="true"
                >
                  <div class="p-2 text-center" v-if="loading">
                    <b-card class="">
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                      <b-skeleton animation="wave" width="67%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>
                      <b-skeleton animation="wave" width="82%"></b-skeleton>
                      <b-skeleton animation="wave" width="65%"></b-skeleton>
                      <b-skeleton animation="wave" width="90%"></b-skeleton>
                      <b-skeleton animation="wave" width="95%"></b-skeleton>
                      <b-skeleton animation="wave" width="82%"></b-skeleton>
                      <b-skeleton animation="wave" width="65%"></b-skeleton>
                      <b-skeleton animation="wave" width="90%"></b-skeleton>
                    </b-card>
                  </div>

                  <div v-else>
                    <!-- reports start -->
                    <blockquote
                      class="blockquote"
                      v-if="paymentLogExists(index)"
                    >
                      <p>
                        Payment Log
                        <button
                          class="btn btn-xs btn-success float-right p-1"
                          @click="showReport(index, 'paymentlog')"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </p>
                    </blockquote>

                    <blockquote
                      class="blockquote"
                      v-if="outstandingLogExists(index)"
                    >
                      <p>
                        Review of Outstanding Visits
                        <button
                          class="btn btn-xs btn-success float-right p-1"
                          @click="showReport(index, 'outstandingvisits')"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </p>
                    </blockquote>

                    <blockquote
                      class="blockquote"
                      v-if="corporatefirstvisitsLogExists(index)"
                    >
                      <p>
                        Update on Corporate Visits & First Visits
                        <button
                          class="btn btn-xs btn-success float-right p-1"
                          @click="showReport(index, 'corporatevisits')"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </p>
                    </blockquote>

                    <blockquote
                      class="blockquote"
                      v-if="elderlyremarkLogExists(index)"
                    >
                      <p>
                        Elderly Remarks for Visits in the Previous Week
                        <button
                          class="btn btn-xs btn-success float-right p-1"
                          @click="showReport(index, 'elderlyremarks')"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </p>
                    </blockquote>

                    <blockquote
                      class="blockquote"
                      v-if="weeklyreportLogExists(index)"
                    >
                      <p>
                        Outstanding Payments/Weekly Update
                        <button
                          class="btn btn-xs btn-success float-right p-1"
                          @click="showReport(index, 'weeklyreport')"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </p>
                    </blockquote>
                    <!-- reports end -->

                    <!-- incident report start -->
                    <blockquote
                      class="blockquote"
                      style="border-left: 1px solid red"
                      v-for="incident in incidents[index]"
                      :key="index + '_' + incident.id"
                    >
                      <p>
                        {{ incident.title }}
                        <router-link
                          :to="`/incidents/view/${incident.id}`"
                          class="btn btn-xs float-right p-1"
                          :class="{
                            'btn-danger': incident.status == 'unresolved',
                            'btn-success': incident.status != 'unresolved',
                          }"
                        >
                          <i class="fa fa-flag"></i>
                        </router-link>
                      </p>
                    </blockquote>
                    <!-- incident report end -->

                    <div
                      class="card task-text-progress ui-sortable-handle"
                      style=""
                      v-for="report in reports[index]"
                      :key="report.id"
                    >
                      <div class="card-body">
                        <div class="task-header">
                          <div class="">
                            <h4 class="">{{ report.user.firstname }}</h4>
                          </div>

                          <div class="">
                            <router-link
                              :to="`/tasks/grade/${report.id}`"
                              v-if="
                                user.role == 'management' ||
                                user.role == 'admin'
                              "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-edit-2 s-task-edit"
                              >
                                <path
                                  d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                                ></path>
                              </svg>
                            </router-link>

                            <router-link
                              style="color: #9ba0b6"
                              :to="`/tasks/view/${report.id}`"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-eye"
                              >
                                <path
                                  d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                ></path>
                                <circle cx="12" cy="12" r="3"></circle>
                              </svg>
                            </router-link>

                            <router-link
                              v-if="
                                (editIndex == index &&
                                  user.id == report.user.id) ||
                                (editIndex == index &&
                                  (user.role == 'management' ||
                                    user.role == 'admin'))
                              "
                              style="color: #9ba0b6"
                              class="m-1"
                              :to="`/tasks/edit/${report.id}`"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-edit"
                              >
                                <path
                                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                ></path>
                                <path
                                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                ></path>
                              </svg>
                            </router-link>
                          </div>
                        </div>

                        <div class="task-body">
                          <div class="task-content">
                            <div class="">
                              <div class="progress br-30">
                                <div
                                  class="progress-bar bg-success"
                                  role="progressbar"
                                  :data-progressstate="report.score"
                                  :style="`width: ${report.score}%`"
                                  :aria-valuenow="report.score"
                                  :aria-valuemin="report.score"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <p class="progress-count">
                                {{ report.score }}pts
                              </p>
                            </div>
                            <div>
                              <button
                                class="badge badge-dark"
                                v-if="
                                  report.user.role == 'admin' &&
                                  report.outstanding_visit_report != null
                                "
                              >
                                Review of Outstanding Visits
                              </button>

                              <button
                                class="badge badge-success"
                                v-if="
                                  report.user.role == 'accounts' &&
                                  report.payment_log != null
                                "
                              >
                                Payment Log Report
                              </button>

                              <button
                                class="badge badge-secondary"
                                v-if="
                                  report.user.role == 'medical services' &&
                                  report.corporate_client_update != null
                                "
                              >
                                Update on Corporate Visits/First Visits
                              </button>

                              <button
                                class="badge badge-primary"
                                v-if="
                                  report.user.role == 'elderly services' &&
                                  report.elderly_remark != null
                                "
                              >
                                Elderly Remarks for Visits
                              </button>

                              <button
                                class="badge badge-warning"
                                v-if="
                                  report.user.role == 'client services' &&
                                  report.general_report != null
                                "
                              >
                                Outstanding Payments/Weekly Update
                              </button>
                            </div>
                          </div>

                          <div class="task-bottom">
                            <small data-taskdate="08 Aug 2019">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-message-square"
                              >
                                <path
                                  d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"
                                ></path>
                              </svg>
                              {{ report.feedbacks_count }}</small
                            >

                            <div class="tb-section-1">
                              <small data-taskdate="08 Aug 2019"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-calendar"
                                >
                                  <rect
                                    x="3"
                                    y="4"
                                    width="18"
                                    height="18"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <line x1="16" y1="2" x2="16" y2="6"></line>
                                  <line x1="8" y1="2" x2="8" y2="6"></line>
                                  <line x1="3" y1="10" x2="21" y2="10"></line>
                                </svg>
                                {{ formatFancyDate(report.created_at) }}</small
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="!loading">
                  <div class="add-s-task" v-if="isCurrentWeek(week, index)">
                    <router-link to="/tasks/grade" class="addTask btn btn-block"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-plus-circle"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                      </svg>
                      Add Report</router-link
                    >
                  </div>
                  <button
                    class="btn btn-xs btn-outline-dark btn-block mt-3"
                    v-if="weekIsPast(week)"
                    @click.prevent="generateEntireWeekPDF(week, index)"
                    :disabled="generateLoading[index]"
                  >
                    <b-spinner small v-if="generateLoading[index]"></b-spinner>
                    <span v-else
                      >Generate PDF <i class="fa fa-file-pdf"></i
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Modal -->
    <div
      class="modal fade"
      id="searchModal"
      role="dialog"
      aria-labelledby="searchModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="monthSearch">Month</label>
                  <select
                    class="form-control"
                    v-model="search.month"
                    id="monthSearch"
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="yearSearch">Year</label>
                  <select
                    class="form-control"
                    v-model="search.year"
                    id="yearSearch"
                  >
                    <option :value="year" v-for="year in years" :key="year">
                      {{ year }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-block btn-success"
              @click.prevent="startFetchTasks()"
            >
              Search <i class="fa fa-search"></i>
            </button>
            <button class="btn-block btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- flowchart modal -->
    <div
      class="modal fade"
      id="flowChartModal"
      role="dialog"
      aria-labelledby="flowChartModalLabel"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="flowChartModalLabel">
              Flow Chart for Weekly Report
            </h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="container mt-container">
                  <ul class="modern-timeline pl-0">
                    <li>
                      <div class="modern-timeline-badge"></div>
                      <div class="modern-timeline-panel">
                        <div class="modern-timeline-body text-center">
                          <h4 class="mb-4">Click on 'Add Report'</h4>
                          <p class="mb-4">
                            Click on the button to add your tasks for the
                            current week. If you have a task entry for the
                            previous week, you will redirectd to a page to grade
                            the tasks for the previous week. If you are a new
                            user, you will be taken straight to the page to add
                            your tasks for that week.
                          </p>
                          <p>
                            <a
                              href="javascript:void(0);"
                              class="btn btn-primary mt-2"
                              >Step 1</a
                            >
                          </p>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="modern-timeline-badge"></div>
                      <div class="modern-timeline-panel">
                        <div class="modern-timeline-body text-center">
                          <h4 class="mb-4">
                            Grade Previous Tasks (Existing users)
                          </h4>
                          <p class="mb-4">
                            If you an existing user, you will be taken to a page
                            to grade your tasks for the past week. Tap on the
                            green checks to indicate tasks that were completed
                            and your points will be calculated automatically.
                          </p>
                          <p>
                            <a
                              href="javascript:void(0);"
                              class="btn btn-primary mt-2"
                              >Step 2</a
                            >
                          </p>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="modern-timeline-badge"></div>
                      <div class="modern-timeline-panel">
                        <div class="modern-timeline-body text-center">
                          <h4 class="mb-4">Creat New Report</h4>
                          <p class="mb-4">
                            You will be redirected to page where you can create
                            your report for the current week. Add your tasks
                            based on the various sections - Routine, New and
                            Other tasks. Outstanding tasks are auto-populated
                            from the previous week. Each of these sections also
                            have a description underneath. Complete the various
                            sections and click on save report. A mail will be
                            sent to you containing the previous week's report
                            you just graded (for exisiting users) and the one
                            you just added for the current week. The mail also
                            includes a PDF file with the same content.
                          </p>
                          <p>
                            <a
                              href="javascript:void(0);"
                              class="btn btn-primary mt-2"
                              >Step 3</a
                            >
                          </p>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="modern-timeline-badge"></div>
                      <div class="modern-timeline-panel">
                        <div class="modern-timeline-body text-center">
                          <h4 class="mb-4">Sidenote</h4>
                          <p class="mb-4">
                            You can always view you tasks and that of other
                            staff for all the weeks in the calender. You can
                            however only edit yours and it must be within the
                            current week. Management and the Admin users can
                            however edit, grade and view whichever report they
                            want to and they can do this at any time.
                          </p>
                          <p>
                            <a
                              href="javascript:void(0);"
                              class="btn btn-primary mt-2"
                              >Step 4</a
                            >
                          </p>
                        </div>
                      </div>
                    </li>

                    <li class="position-static">
                      <div class="modern-timeline-top"></div>
                    </li>
                    <li class="position-static">
                      <div class="modern-timeline-bottom"></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- cummulative scores modal -->
    <div
      class="modal fade"
      id="scoreChartModal"
      role="dialog"
      aria-labelledby="scoreChartModalLabel"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="scoreChartModalLabel">
              Cummulative scores for Weekly Report
            </h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <form class="row">
                  <div class="col-12 text-center">
                    <p class="text-center">From</p>
                  </div>

                  <div class="form-group col-12 col-md-6">
                    <label for="startMonth"></label>
                    <select
                      class="form-control"
                      v-model="startMonth"
                      id="startMonth"
                    >
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>

                  <div class="form-group col-12 col-md-6">
                    <label for="startYear"> </label>
                    <select
                      class="form-control"
                      v-model="startYear"
                      id="startYear"
                    >
                      <option :value="year" v-for="year in years" :key="year">
                        {{ year }}
                      </option>
                    </select>
                  </div>

                  <div class="col-12 text-center">
                    <p class="text-center">To</p>
                  </div>

                  <div class="form-group col-12 col-md-6">
                    <label for="endMonth"></label>
                    <select
                      class="form-control"
                      v-model="endMonth"
                      id="endMonth"
                    >
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>

                  <div class="form-group col-12 col-md-6">
                    <label for="endYear"> </label>
                    <select class="form-control" v-model="endYear" id="endYear">
                      <option :value="year" v-for="year in years" :key="year">
                        {{ year }}
                      </option>
                    </select>
                  </div>

                  <div class="col-12">
                    <button
                      class="btn btn-block btn-success"
                      @click.prevent="calculatePoints()"
                      :disabled="loadingCalculate"
                    >
                      <b-spinner small v-if="loadingCalculate"></b-spinner>
                      <span v-else
                        >Calculate <i class="fa fa-paper-plane"></i
                      ></span>
                    </button>
                  </div>
                </form>
              </div>

              <div class="col-12 mt-4">
                <div class="bg-light">
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- report modal -->
    <div
      class="modal fade"
      id="reportModal"
      role="dialog"
      aria-labelledby="reportModalLabel"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              v-if="reportToShow.type != ''"
              id="reportModalLabel"
            >
              <span v-if="reportToShow.type == 'paymentlog'">Payment Log </span>
              <span v-if="reportToShow.type == 'outstandingvisits'"
                >Review of Outstanding Visits</span
              >
              <span v-if="reportToShow.type == 'corporatevisits'"
                >Update on Corporate Visits & First Visits
              </span>
              <span v-if="reportToShow.type == 'elderlyremarks'"
                >Elderly Remarks for Visits in the Previous Week
              </span>
              <span v-if="reportToShow.type == 'weeklyreport'"
                >Outstanding Payments/Weekly Update
              </span>
            </h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div
                class="col-12"
                v-for="(item, index) in reportToShow.reports"
                :key="index"
              >
                <p>
                  {{ item }}
                </p>
                <hr />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { mapGetters } from "vuex";
import $ from "jquery";
import _ from "lodash";
var moment = require("moment");

export default {
  name: "Tasks",

  computed: {
    ...mapGetters(["url", "user"]),
  },

  data() {
    return {
      loading: true,

      generateLoading: {
        0: false,
        1: false,
        2: false,
        3: false,
      },

      years: [],
      search: {
        month: "",
        year: "",
      },
      currentDay: null,
      weeks: [],

      reports: [],

      editIndex: 0,

      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",

      loadingCalculate: false,

      series: [
        {
          data: [],
        },
      ],
      chartOptions: {},

      reportToShow: {
        type: "",
        reports: [],
      },
    };
  },

  mounted() {
    this.calcLastThreeYears();
    this.calcLast4Weeks();
    this.currentDay = moment().format("YYYY-MM-DD"); //so we can send reports on monday

    this.fetchTasks();
  },

  destroyed() {},

  methods: {
    calcLastThreeYears() {
      this.search.year = moment().format("YYYY");
      this.search.month = moment().format("MMMM");
      var year = moment().format("YYYY");
      for (let index = 0; index < 4; index++) {
        this.years.push(year--);
      }
    },

    calcLast4Weeks() {
      let weekStart = moment(
        `${this.search.month} ${this.search.year}`,
        "MMMM-YYYY"
      )
        .clone()
        .endOf("month")
        .startOf("isoweek");
      var e = 0;
      var w = [];
      w.push(
        moment(weekStart)
          .add(1, "weeks")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      );
      while (e < 5) {
        w.push(moment(weekStart).subtract(e, "weeks").format("YYYY-MM-DD"));
        e++;
      }
      var i = 0;
      var d = [];
      var v = [];
      v.push(w[1]);
      v.push(w[0]);
      d.push(v);
      while (i < 4) {
        v = [];
        v.push(w[i + 2]);
        v.push(
          moment(w[i + 1])
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        );
        d.push(v);
        i++;
      }
      this.weeks = d.reverse();
    },

    isCurrentWeek(week, index) {
      if (
        moment(this.currentDay).isBetween(week[0], week[1]) ||
        moment(this.currentDay).isSame(week[0]) ||
        moment(this.currentDay).isSame(week[1])
      ) {
        //check if user has sent in report already
        var data = "";
        data = this.reports[index].filter((x) => this.user.id == x.user.id);
        this.editIndex = index;
        return data == "" ? true : false;
      } else {
        return false;
      }
    },

    weekIsPast(week) {
      var weekStartDate = week[0];
      var result = false;

      if (moment(this.currentDay).isSameOrAfter(weekStartDate)) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },

    startFetchTasks() {
      this.calcLast4Weeks();
      this.fetchTasks();
      $("#searchModal").modal("hide");
    },

    fetchTasks() {
      this.loading = true;
      http
        .post(endpoints.FETCH_TASKS, {
          weeks: this.weeks,
        })
        .then((response) => {
          this.reports = response.records;
          this.incidents = response.incidents;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    calculatePoints() {
      if (
        this.startMonth == "" ||
        this.startYear == "" ||
        this.endMonth == "" ||
        this.endYear == ""
      ) {
        this.$toast.error("Missing required fields");
      } else {
        this.loadingCalculate = true;
        http
          .post(endpoints.CALCULATE_TASK_POINTS, {
            startMonth: this.startMonth,
            startYear: this.startYear,
            endMonth: this.endMonth,
            endYear: this.endYear,
          })
          .then((response) => {
            response = _.orderBy(response, "points").reverse();
            this.loadingCalculate = false;

            this.series[0].data = [];
            response.forEach((x) => {
              this.series[0].data.push(x.points);
            });

            var data = [];
            response.forEach((x) => {
              data.push(x.firstname);
            });

            this.chartOptions = {
              chart: {
                type: "bar",
                height: 350,
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                  horizontal: true,
                },
              },
              dataLabels: {
                enabled: true,
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return Math.round(val);
                  },
                },
              },
              xaxis: {
                categories: data,
              },
            };
          })
          .catch((error) => {
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
            this.loadingCalculate = false;
          });
      }
    },

    paymentLogExists(index) {
      var check = false;
      this.reports[index].forEach((report) => {
        if (
          report.user.role == "accounts" &&
          report.payment_log != null &&
          report.payment_log != ""
        ) {
          check = true;
        }
      });
      return check;
    },

    outstandingLogExists(index) {
      var check = false;
      this.reports[index].forEach((report) => {
        if (
          report.user.role == "admin" &&
          report.outstanding_visit_report != null &&
          report.outstanding_visit_report != ""
        ) {
          check = true;
        }
      });
      return check;
    },

    corporatefirstvisitsLogExists(index) {
      var check = false;
      this.reports[index].forEach((report) => {
        if (
          report.user.role == "medical services" &&
          report.corporate_client_update != null &&
          report.corporate_client_update != ""
        ) {
          check = true;
        }
      });
      return check;
    },

    elderlyremarkLogExists(index) {
      var check = false;
      this.reports[index].forEach((report) => {
        if (
          report.user.role == "elderly services" &&
          report.elderly_remark != null &&
          report.elderly_remark != ""
        ) {
          check = true;
        }
      });
      return check;
    },

    weeklyreportLogExists(index) {
      var check = false;
      this.reports[index].forEach((report) => {
        if (
          report.user.role == "client services" &&
          report.general_report != null &&
          report.general_report != ""
        ) {
          check = true;
        }
      });
      return check;
    },

    showReport(index, type) {
      this.reportToShow.type = type;
      this.reportToShow.reports = [];

      if (type == "paymentlog") {
        this.reports[index].forEach((report) => {
          if (
            report.user.role == "accounts" &&
            report.payment_log != null &&
            report.payment_log != ""
          ) {
            this.reportToShow.reports.push(report.payment_log);
          }
        });
      }

      if (type == "outstandingvisits") {
        this.reports[index].forEach((report) => {
          if (
            report.user.role == "admin" &&
            report.outstanding_visit_report != null &&
            report.outstanding_visit_report != ""
          ) {
            this.reportToShow.reports.push(report.outstanding_visit_report);
          }
        });
      }

      if (type == "corporatevisits") {
        this.reports[index].forEach((report) => {
          if (
            report.user.role == "medical services" &&
            report.corporate_client_update != null &&
            report.corporate_client_update != ""
          ) {
            this.reportToShow.reports.push(report.corporate_client_update);
          }
        });
      }

      if (type == "elderlyremarks") {
        this.reports[index].forEach((report) => {
          if (
            report.user.role == "elderly services" &&
            report.elderly_remark != null &&
            report.elderly_remark != ""
          ) {
            this.reportToShow.reports.push(report.elderly_remark);
          }
        });
      }

      if (type == "weeklyreport") {
        this.reports[index].forEach((report) => {
          if (
            report.user.role == "client services" &&
            report.general_report != null &&
            report.general_report != ""
          ) {
            this.reportToShow.reports.push(report.general_report);
          }
        });
      }

      $("#reportModal").modal("show");
    },

    generateEntireWeekPDF(week, index) {
      this.generateLoading[index] = true;
      http
        .post(endpoints.GENERATE_ENTIRE_WEEK_PDF, {
          start: week[0],
          end: week[1],
        })
        .then(() => {
          this.generateLoading[index] = false;
          this.$toast.info("The pdf is on its way to your mailbox");
        })
        .catch((error) => {
          this.generateLoading[index] = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/apps/scrumboard.css"></style>
<style scoped src="@/assets/css/forms/theme-checkbox-radio.css"></style>
<style
  scoped
  src="@/assets/css/components/tabs-accordian/custom-accordions.css"
></style>
<style scoped src="@/assets/css/tables/table-basic.css"></style>

<style lang="scss" scoped>
.task-bottom {
  font-size: 12px !important;
}
</style>
